.loader-container { /* Container to center the animation */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Ensure it's on top */
}

.loader {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 5px solid transparent; /* Transparent border initially */
  border-top-color: #D2B48C; /* Bakery brown */
  animation: spin 1s linear infinite; /* Animation for the spinning effect */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
